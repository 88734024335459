<template>
    <v-list dense v-if="view">
        <v-subheader v-text="$t('views.sections.general')" />
        <v-list-item>
            <v-list-item-content
                class="font-weight-medium blue-grey--text text--darken-3"
                v-text="$t('views.attributes.group')"
            />
            <v-list-item-content class="py-0 align-end">
                {{ view.group }}
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content
                class="font-weight-medium blue-grey--text text--darken-3"
                v-text="$t('views.attributes.name')"
            />
            <v-list-item-content class="py-0 align-end">
                {{ view.name }}
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content
                class="font-weight-medium blue-grey--text text--darken-3"
                v-text="$t('views.attributes.label')"
            />
            <v-list-item-content class="py-0 align-end">
                {{ view.label }}
            </v-list-item-content>
        </v-list-item>
        <v-subheader v-text="$t('views.sections.filters')" />
        <v-list-item>
            <v-list-item-content
                class="font-weight-medium blue-grey--text text--darken-3"
                v-text="$t('views.attributes.filter')"
            />
            <v-list-item-content class="py-0 align-end">
                <v-chip-group>
                    <v-chip v-for="(f, index) in view.filters" :key="index" small outlined color="success">
                        {{ f.label }}
                    </v-chip>
                </v-chip-group>
            </v-list-item-content>
        </v-list-item>
        <v-subheader v-text="$t('views.sections.cols')" />
        <v-list-item>
            <v-list-item-content
                class="font-weight-medium blue-grey--text text--darken-3"
                v-text="$t('views.attributes.cols')"
            />
            <v-list-item-content class="py-0 align-end">
                <span v-for="(c, index) in view.cols" :key="index" v-text="$t('issues.attributes.' + c)" />
            </v-list-item-content>
        </v-list-item>
        <v-subheader v-text="$t('views.sections.group')" />
        <v-list-item>
            <v-list-item-content
                class="font-weight-medium blue-grey--text text--darken-3"
                v-text="$t('views.attributes.order')"
            />
            <v-list-item-content class="py-0 align-end">
                {{ $t('issues.attributes.' + view.defaultOrder.field) }} -
                {{ $t('views.order.' + view.defaultOrder.sort) }}
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content
                class="font-weight-medium blue-grey--text text--darken-3"
                v-text="$t('views.attributes.groupCol')"
            />
            <v-list-item-content class="py-0 align-end">
                <span v-if="view.groupCol">{{ $t('issues.attributes.' + view.groupCol) }}</span>
                <span v-else>-</span>
            </v-list-item-content>
        </v-list-item>
        <v-subheader v-text="$t('views.sections.security')" />
        <v-list-item>
            <v-list-item-content
                class="font-weight-medium blue-grey--text text--darken-3"
                v-text="$t('views.attributes.securityRoles')"
            />
            <v-list-item-content class="py-0 align-end">
                <label-roles :value="view.security.roles" color="grey lighten-2" :dark="false" />
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content
                class="font-weight-medium blue-grey--text text--darken-3"
                v-text="$t('views.attributes.securityUsers')"
            />
            <v-list-item-content class="py-0 align-end">
                <label-users :value="view.security.users" color="blue-grey lighten-2" :dark="false" />
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import labelRoles from '@/components/labels/roles';
import labelUsers from '@/components/labels/users';

export default {
    name: 'view-resume',
    data: () => ({}),
    props: {
        view: {
            required: true,
        },
    },
    components: {
        labelRoles,
        labelUsers,
    },
};
</script>
