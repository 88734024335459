<template>
    <v-container>
        <v-row>
            <v-col>
                <h5 class="text-h5 blue--text text--darken-1" v-text="$t('views.create')" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-stepper v-model="step">
                    <v-stepper-header>
                        <v-stepper-step :complete="step > 1" step="1">
                            {{ $t('views.sections.general') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="step > 2" step="2">
                            {{ $t('views.sections.filters') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="step > 3" step="3">
                            {{ $t('views.sections.cols') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="step > 4" step="4">
                            {{ $t('views.sections.group') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="step > 5" step="5">
                            {{ $t('views.sections.security') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="step > 6" step="6">
                            {{ $t('views.sections.resume') }}
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items flat>
                        <v-stepper-content step="1">
                            <ValidationObserver ref="observer" v-slot="{ invalid }">
                                <v-container>
                                    <v-row>
                                        <v-col>
                                            <ValidationProvider
                                                :name="$t('views.attributes.group')"
                                                :rules="'required'"
                                                v-slot="{ errors }"
                                            >
                                                <v-text-field
                                                    v-model="view.group"
                                                    :counter="20"
                                                    :label="$t('views.attributes.group')"
                                                    required
                                                    outlined
                                                    :error-messages="errors[0]"
                                                    color="red"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <ValidationProvider
                                                :name="$t('views.attributes.label')"
                                                :rules="'required'"
                                                v-slot="{ errors }"
                                            >
                                                <v-text-field
                                                    v-model="view.label"
                                                    :counter="20"
                                                    :label="$t('views.attributes.label')"
                                                    required
                                                    outlined
                                                    :error-messages="errors[0]"
                                                    color="red"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider
                                                :name="$t('views.attributes.name')"
                                                :rules="'required'"
                                                v-slot="{ errors }"
                                            >
                                                <v-text-field
                                                    v-model="view.name"
                                                    :counter="20"
                                                    :label="$t('views.attributes.name')"
                                                    required
                                                    outlined
                                                    :error-messages="errors[0]"
                                                    color="red"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-btn
                                                small
                                                text
                                                color="red"
                                                @click="cancel"
                                                v-text="$t('buttons.cancel')"
                                            />
                                            <v-btn
                                                small
                                                color="primary"
                                                @click="step++"
                                                v-text="$t('buttons.continue')"
                                                :disabled="invalid"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </ValidationObserver>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <viewFilter v-model="view.filters" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            small
                                            text
                                            color="red"
                                            @click="$router.go(-1)"
                                            v-text="$t('buttons.cancel')"
                                        />
                                        <v-btn small text v-text="$t('buttons.back')" @click="step--" />
                                        <v-btn small color="primary" @click="step++" v-text="$t('buttons.continue')" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <col-selector v-model="view.cols" />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-btn
                                            small
                                            text
                                            color="red"
                                            @click="$router.go(-1)"
                                            v-text="$t('buttons.cancel')"
                                        />
                                        <v-btn small text v-text="$t('buttons.back')" @click="step--" />
                                        <v-btn small color="primary" @click="step++" v-text="$t('buttons.continue')" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-stepper-content>

                        <v-stepper-content step="4">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-subheader v-text="$t('views.order_applied')" />
                                    </v-col>
                                </v-row>
                                <v-row align="center">
                                    <orderSelector v-model="view.defaultOrder" />
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-subheader v-text="$t('views.group_applied')" />
                                    </v-col>
                                </v-row>
                                <v-row align="center">
                                    <v-col class="py-0" cols="12" md="6">
                                        <groupSelector v-model="view.groupCol" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            small
                                            text
                                            color="red"
                                            @click="$router.go(-1)"
                                            v-text="$t('buttons.cancel')"
                                        />
                                        <v-btn small text v-text="$t('buttons.back')" @click="step--" />
                                        <v-btn small color="primary" @click="step++" v-text="$t('buttons.continue')" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-stepper-content>

                        <v-stepper-content step="5">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-subheader v-text="$t('views.security_roles')" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <roles-selector label="" v-model="view.security.roles" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-subheader v-text="$t('views.security_users')" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <users-selector label="" v-model="view.security.users" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            small
                                            text
                                            color="red"
                                            @click="$router.go(-1)"
                                            v-text="$t('buttons.cancel')"
                                        />
                                        <v-btn small text v-text="$t('buttons.back')" @click="step--" />
                                        <v-btn small color="primary" @click="step++" v-text="$t('buttons.continue')" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-stepper-content>

                        <v-stepper-content step="6">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <view-resume :view="view" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn small text color="red" @click="cancel" v-text="$t('buttons.cancel')" />
                                        <v-btn small text v-text="$t('buttons.back')" @click="step--" />
                                        <v-btn
                                            small
                                            text
                                            color="success"
                                            @click="createView"
                                            v-text="$t('buttons.save')"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { issuesHeaders } from '@/config/views';
import viewFilter from '@/components/views/filter';
import rolesSelector from '@/components/selectors/multiple/roles';
import usersSelector from '@/components/selectors/multiple/users';
import colSelector from '@/components/views/colSelector';
import orderSelector from '@/components/views/orderSelector';
import groupSelector from '@/components/views/groupSelector';
import viewResume from './view';

export default {
    name: 'view-create',
    data: () => ({
        step: 1,
        view: {
            name: undefined,
            label: undefined,
            group: undefined,
            cols: ['name', 'status'],
            defaultOrder: {
                field: 'createdAt',
                sort: 1,
            },
            groupCol: '',
            filters: [],
            security: {
                roles: [],
                users: [],
            },
        },
    }),
    props: {},
    components: {
        colSelector,
        orderSelector,
        groupSelector,
        viewFilter,
        rolesSelector,
        usersSelector,
        viewResume,
    },
    mounted() {},
    methods: {
        createView() {
            this.$emit('create-view', this.view);
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>
